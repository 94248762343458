<template>
  <div class="services_section">
    <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ checkBn ? headerText.text_bn : headerText.text_en }}</h2>
<!--      <h2 class="cover_title">{{ checkBn ?  getParentName($route.query.service_type, parentId).name_bn : getParentName($route.query.service_type, parentId).name }}</h2>-->
      <img src="../../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item"><router-link to="/portal/all-services">{{ $t('portal.all_service') }}</router-link></li>
                <li class="breadcrumb-item"><a href="javascript:" @click="$router.go(-1)">{{ $t('portal.service_details') }}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('externalIncentive.circular_list') }}</li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="service_details_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <div class="service_details">
              <iq-card>
                <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('externalIncentive.circular_list') }}</h4>
                </template>
                <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('globalTrans.fiscal_year')"
                          label-for="fiscal_year_id"
                          >
                          <b-form-select
                              plain
                              v-model="search.fiscal_year_id"
                              :options="fiscalList"
                              id="fiscal_year_id"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
                </template>
            </iq-card>
            <b-row>
                <b-col md="12">
                    <iq-card>
                        <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('externalIncentive.circular_list') }}</h4>
                        </template>
                        <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                            <b-col md="12" class="table-responsive">
                                <b-table :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                    <template v-slot:cell(index)="data">
                                    {{ $n(data.index + pagination.slOffset) }}
                                    </template>
                                    <template v-slot:cell(amount)="data">
                                    {{ $n(data.item.amount) }}
                                    </template>
                                    <template v-slot:cell(status)="data">
                                    <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                    <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                    </template>
                                    <template v-slot:cell(action)="data">
                                    <b-button class="mr-1" :title="$t('globalTrans.details')" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="fa fa-eye "></i></b-button>
                                    <!-- <router-link :to="{ path: '/incentive-farmer/subsidy-application-form?circularId=' + data.item.id }" size="sm">
                                        {{ $t('externalIncentive.apply') }}
                                    </router-link> -->
                                    <a href="javascript:" @click="setServiceUrl(data.item.id)" :to="{ path: '/incentive-farmer/subsidy-application-form?circularId=' + data.item.id }" size="sm">
                                        {{ $t('externalIncentive.apply') }}
                                    </a>
                                    </template>
                                </b-table>
                                <b-pagination
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total-rows="pagination.totalRows"
                                @input="searchData"
                                />
                            </b-col>
                            </b-row>
                        </b-overlay>
                        </template>
                    </iq-card>
                </b-col>
            </b-row>
            <b-modal id="modal-5" size="lg" :title="$t('externalIncentive.circular')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                <Details :item="item" :key="item"></Details>
            </b-modal>
            </div>
          </b-col>
        </b-row>
        <div class="service_btn_wrapper">
            <a href="javascript:" @click="$router.go(-1)" class="service_btn back_btn"><i class="fas fa-arrow-left"></i> {{ $t('portal.go_back') }}</a>
            <!-- <router-link v-if="service.pr_apply_btn" :to="service.service_url" class="service_btn send_btn"><i class="fas fa-paper-plane"></i> {{ $t('portal.apply_now') }}</router-link> -->
            <a href="javascript:void" v-if="service.pr_apply_btn" @click="setServiceUrl(service.service_url)" class="service_btn send_btn"><i class="fas fa-paper-plane"></i> {{ $t('portal.apply_now') }}</a>
        </div>
        <b-row v-if="service.pr_rating">
          <b-col>
            <div class="rating_review_wrapper">
              <b-card header="Ratings & Reviews of Irrigation Scheme" header-tag="header">
                <b-card-text>
                  <b-row>
                    <b-col sm="2">
                      <div class="text-center">
                        <!-- <h4 class="rating_title">Ratings</h4> -->
                        <div class="rating_circle my-2">4.1<sub style="color: #9e9e9e;">/5</sub></div>
                        <div class="stars-outer">
                          <div class="stars-inner"></div>
                        </div>
                        <div style="color: #9e9e9e;"><small>440 Ratings</small></div>
                      </div>
                    </b-col>
                    <b-col sm="4">
                      <div class="progress mt-4" style="height:10px">
                        <div class="progress-bar dark" style="width:70%;height:10px"></div>
                      </div>
                      <div class="progress mt-3" style="height:10px">
                        <div class="progress-bar dark" style="width:10%;height:10px"></div>
                      </div>
                      <div class="progress mt-3" style="height:10px">
                        <div class="progress-bar dark" style="width:10%;height:10px"></div>
                      </div>
                      <div class="progress mt-3" style="height:10px">
                        <div class="progress-bar dark" style="width:5%;height:10px"></div>
                      </div>
                      <div class="progress mt-3" style="height:10px">
                        <div class="progress-bar dark" style="width:5%;height:10px"></div>
                      </div>
                    </b-col>
                    <b-col sm="1" class="d-none d-sm-block">
                      <div class="row">
                        <h6 class="rating_text rating_text_common">5</h6>
                      </div>
                      <div class="row">
                        <h6 class="rating_text_common">4</h6>
                      </div>
                      <div class="row">
                        <h6 class="rating_text_common">3</h6>
                      </div>
                      <div class="row">
                        <h6 class="rating_text_common">2</h6>
                      </div>
                      <div class="row">
                        <h6 class="rating_text_common">1</h6>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
                <b-card-text>
                  <div class="reviews_wrapper">
                    <h5 class="reviews_title">Reviews</h5>
                    <div class="review_item">
                      <div class="rating_counter">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div>
                      <p class="date">11 Jul 2021</p>
                      <p class="user_wrapper">by Md. Tawhid <span class="verified"><i class="fas fa-user-shield"></i> Verified Purchase</span></p>
                      <p class="comment">ফল অার্মিওয়ার্ম ভূট্টা ভিন্ন অন্য কোন ফসলে অাক্রমন করে? বাংলাদেশে কোন অঞ্চলে এ পোকার  অাক্রমন দেখা দিয়েছে কিনা? বিস্তারিত জানতে চাই। ফল অার্মিওয়ার্ম ভূট্টা ভিন্ন অন্য কোন ফসলে অাক্রমন করে? বাংলাদেশে কোন অঞ্চলে এ পোকার  অাক্রমন দেখা দিয়েছে কিনা? বিস্তারিত জানতে চাই।</p>
                    </div>
                    <div class="review_item">
                      <div class="rating_counter">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div>
                      <p class="date">11 Jul 2021</p>
                      <p class="user_wrapper">by Md. Tawhid <span class="verified"><i class="fas fa-user-shield"></i> Verified Purchase</span></p>
                      <p class="comment">ফল অার্মিওয়ার্ম ভূট্টা ভিন্ন অন্য কোন ফসলে অাক্রমন করে? বাংলাদেশে কোন অঞ্চলে এ পোকার  অাক্রমন দেখা দিয়েছে কিনা? বিস্তারিত জানতে চাই। ফল অার্মিওয়ার্ম ভূট্টা ভিন্ন অন্য কোন ফসলে অাক্রমন করে? বাংলাদেশে কোন অঞ্চলে এ পোকার  অাক্রমন দেখা দিয়েছে কিনা? বিস্তারিত জানতে চাই।</p>
                    </div>
                    <div class="review_item">
                      <div class="rating_counter">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div>
                      <p class="date">11 Jul 2021</p>
                      <p class="user_wrapper">by Md. Tawhid <span class="verified"><i class="fas fa-user-shield"></i> Verified Purchase</span></p>
                      <p class="comment">ফল অার্মিওয়ার্ম ভূট্টা ভিন্ন অন্য কোন ফসলে অাক্রমন করে? বাংলাদেশে কোন অঞ্চলে এ পোকার  অাক্রমন দেখা দিয়েছে কিনা? বিস্তারিত জানতে চাই। ফল অার্মিওয়ার্ম ভূট্টা ভিন্ন অন্য কোন ফসলে অাক্রমন করে? বাংলাদেশে কোন অঞ্চলে এ পোকার  অাক্রমন দেখা দিয়েছে কিনা? বিস্তারিত জানতে চাই।</p>
                    </div>
                  </div>
                </b-card-text>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import Details from './Details'
import RestApi, { incentiveGrantServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  props: ['serviceUrl', 'headerItem'],
  components: {
    Details
  },
  data () {
    return {
        loading: false,
        headerText: { text_en: '', text_bn: '' },
        service: {},
        search: {
          fiscal_year_id: 0
        },
        item: '',
        parentId: ''
    }
  },
  computed: {
    fiscalList: function () {
      return this.$store.state.Portal.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    subsidyCircularTypeList: function () {
      return this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.subsidyCircularTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('dae_grant_allocation_distribution.fiscal_year'), class: 'text-center' },
          { label: this.$t('barc_config.circular_name'), class: 'text-center' },
          { label: this.$t('dae_config.circular_type'), class: 'text-center' },
          { label: this.$t('dae_subsidy.memo_no'), class: 'text-center' },
          { label: this.$t('globalTrans.amount'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'circular_name_bn' },
          { key: 'circular_type_name_bn' },
          { key: 'memo_no' },
          { key: 'amount' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year' },
          { key: 'circular_name' },
          { key: 'circular_type_name' },
          { key: 'memo_no' },
          { key: 'amount' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  created () {
    this.loadData()
    this.setComp()
    this.setHeaderText()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
    },
    async loadData () {
        this.loading = true
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        await RestApi.getData(incentiveGrantServiceBaseUrl, '/portal/dae-subsidy-circular', params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.loadServiceData()
        this.loading = false
      })
    },
    getRelatinalData (data) {
      const fiscalYearList = this.$store.state.Portal.commonObj.fiscalYearList.filter(item => item.status === 0)
      const subsidyCircularTypeList = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.subsidyCircularTypeList
      return data.map(item => {
        const fiscalObject = fiscalYearList.find(fiscal => fiscal.value === item.fiscal_year_id)
        const circularTypeObject = subsidyCircularTypeList.find(typeItem => typeItem.value === item.circular_type)
        const fiscalyearData = {
          fiscal_year: fiscalObject !== undefined ? fiscalObject.text_en : '',
          fiscal_year_bn: fiscalObject !== undefined ? fiscalObject.text_bn : ''
        }
        const circularTypeData = {
          circular_type_name: circularTypeObject !== undefined ? circularTypeObject.text_en : '',
          circular_type_name_bn: circularTypeObject !== undefined ? circularTypeObject.text_bn : ''
        }
        return Object.assign({}, item, fiscalyearData, circularTypeData)
      })
    },
    setServiceUrl (circularId) {
      this.$store.dispatch('Portal/setServiceUrl', {
        externalPanelServiceUrl: this.serviceUrl,
        externalPanelCurrentServiceUrl: this.serviceUrl,
        subsidyCircularId: circularId
      })
      this.$router.push('/auth/login')
    },
    async loadServiceData () {
      // if (this.$route.query.service_id) {
        this.loading = true
        const result = await RestApi.getData(commonServiceBaseUrl, '/portal/frontend/service/show', { id: this.$route.query.service_id })
        this.loading = false
        if (result.success) {
          this.service = result.data
          this.setSubsidyCircular(this.service.component_id)
        }
      // } else {
      //   this.$router.push('/portal/home')
      // }
    },
    setComp () {
      if (this.$route.query.customer_type_id) {
        this.parentId = this.$route.query.customer_type_id
      } else if (this.$route.query.service_category_id) {
        this.parentId = this.$route.query.service_category_id
      } else {
        this.parentId = this.$route.query.org_id
      }
    },
    setHeaderText () {
      if (this.headerItem === undefined) {
        this.headerText = {
          text_en: '',
          text_bn: ''
        }
        return
      }
      if (this.headerItem.type === 'orgList') {
        const org = this.$store.state.Portal.orgList.find(item => item.id === this.headerItem.id)
        this.headerText = {
          text_en: typeof org !== 'undefined' ? org.abbreviation : '',
          text_bn: typeof org !== 'undefined' ? org.abbreviation_bn : ''
        }
      } else if (this.headerItem.type === 'customerTypeList') {
        const customerType = this.$store.state.Portal.customerTypeList.find(item => item.id === this.headerItem.id)
        this.headerText = {
          text_en: customerType.name,
          text_bn: customerType.name_bn
        }
      } else if (this.headerItem.type === 'categoryList') {
        const category = this.$store.state.Portal.categoryList.find(item => item.id === this.headerItem.id)
        this.headerText = {
          text_en: category.name,
          text_bn: category.name_bn
        }
      } else {
        this.headerText = {
          text_en: 'সকল সেবা',
          text_bn: 'All Services'
        }
      }
    },
    getParentName (parentType, Id) {
      if (parentType) {
        if (parentType === 'allService') {
          return { name: this.$t('portal.all_service'), name_bn: this.$t('portal.all_service') }
        } else {
          const tmpData = this.$store.state.Portal[parentType]
          if (typeof tmpData === 'undefined') {
            return ''
          } else {
            const data = tmpData.find(item => item.id === parseInt(Id))
            if (typeof data === 'undefined') {
              return ''
            } else {
              if (parentType === 'orgList') {
                return Object.assign(data, { name: data.abbreviation, name_bn: data.abbreviation_bn })
              } else {
                return data
              }
            }
          }
        }
      }
    }
  }
}
</script>
