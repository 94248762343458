<template>
  <b-container fluid>
      <b-row class="text-dark">
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row class="details">
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table striped small bordered hover :items="columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
            <template v-if="item.attachment">
              <b-table-simple bordered>
                <b-tr>
                  <b-th>{{ $t('globalTrans.attachment') }}</b-th>
                  <b-td>
                    <a target="_blank" :href="incentiveGrantServiceBaseUrl + item.attachment" title="Attachment" class="mr-3" download="">
                        <i class="fa fa-download"></i>
                    </a>
                  </b-td>
                </b-tr>
              </b-table-simple>
            </template>
            <template v-if="circular.instrument_details.length > 0">
              <h4 class="mb-1">{{ $t('dae_config.instrument_info') }}</h4>
              <b-table-simple bordered>
                <b-tr>
                  <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                  <b-th>{{ $t('farm_config.machine_type') }}</b-th>
                  <b-th>{{ $t('dae_config.instrument_name') }}</b-th>
                </b-tr>
                <b-tr v-for="(instrument, indx) in circular.instrument_details" :key="indx">
                  <b-td>{{ $n(indx + 1) }}</b-td>
                  <b-td>{{ ($i18n.locale=='bn') ? instrument.machine_type.machine_type_bn : instrument.machine_type.machine_type }}</b-td>
                  <b-td>{{ ($i18n.locale=='bn') ? instrument.instrument_name_bn : instrument.instrument_name }}</b-td>
                </b-tr>
              </b-table-simple>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { incentiveGrantServiceBaseUrl } from '@/config/api_config'
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    const instrumentData = this.getInstrumentDetails(this.item.instrument_details)
    this.circular = Object.assign(this.item, instrumentData)
  },
  data () {
    return {
        incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
        circular: {},
        slOffset: 1
    }
  },
  computed: {
    columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('globalTrans.fiscal_year'), val: this.circular.fiscal_year_bn, key1: this.$t('dae_config.season_name'), val1: this.circular.season_name_bn },
          { key: this.$t('dae_config.circular_type'), val: this.circular.circular_type_name_bn, key1: this.$t('barc_config.memo_no'), val1: this.circular.memo_no },
          { key: this.$t('barc_config.circular_name'), val: this.circular.circular_name_bn, key1: this.$t('bsri_config.project_name'), val1: this.circular.project_name ? this.circular.project_name.project_name_bn : '' },
          { key: this.$t('dae_subsidy.application_deadline'), val: this.circular.application_deadline, key1: this.$t('globalTrans.description'), val1: this.circular.description }
        //   { key: this.$t('globalTrans.description'), val: this.circular.description }
        ]
      } else {
        return [
          { key: this.$t('globalTrans.fiscal_year'), val: this.circular.fiscal_year, key1: this.$t('dae_config.season_name'), val1: this.circular.season_name },
          { key: this.$t('dae_config.circular_type'), val: this.circular.circular_type_name, key1: this.$t('barc_config.memo_no'), val1: this.circular.memo_no },
          { key: this.$t('barc_config.circular_name'), val: this.circular.circular_name, key1: this.$t('bsri_config.project_name'), val1: this.circular.project_name ? this.circular.project_name.project_name : '' },
          { key: this.$t('dae_subsidy.application_deadline'), val: this.circular.application_deadline, key1: this.$t('globalTrans.description'), val1: this.circular.description }
        //   { key: this.$t('globalTrans.description'), val: this.circular.description }
        ]
      }
    }
  },
  methods: {
    getCropDetails (data) {
      return data.map(item => {
        const crop = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.cropList.find(crop => crop.value === item.crop_id)
        const cropObj = {
          crop_name: crop !== undefined ? crop.text_en : '',
          crop_name_bn: crop !== undefined ? crop.text_bn : ''
        }
        return Object.assign(item, cropObj)
      })
    },
    getMaterialDetails (data) {
      return data.map(item => {
        const matType = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.agMaterialTypeList.find(mtp => mtp.value === item.material_type_id)
        const mat = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.agMaterialList.find(mt => mt.value === item.material_id)
        const matTypeObj = {
          material_type_name: matType !== undefined ? matType.text_en : '',
          material_type_name_bn: matType !== undefined ? matType.text_bn : ''
        }
        const matObj = {
          material_name: mat !== undefined ? mat.text_en : '',
          material_name_bn: mat !== undefined ? mat.text_bn : ''
        }
        return Object.assign(item, matTypeObj, matObj)
      })
    },
    getInstrumentDetails (data) {
      return data.map(item => {
        const instrument = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.instrumentList.find(inst => inst.value === item.instrument_id)
        const instrumentObj = {
          instrument_name: instrument !== undefined ? instrument.text_en : '',
          instrument_name_bn: instrument !== undefined ? instrument.text_bn : ''
        }
        return Object.assign(item, instrumentObj)
      })
    }
  }
}
</script>
<style lang="scss">
  .details {
    h5 {
      color: green !important;
      font-size: 20px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
  }
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
